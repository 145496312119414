<template>
  <div>
    <CRow>
      <CCol md="12">
        <!-- <div style="float: right; text-align: right;"><h1 style="font-size: 1.5rem">Puntos: {{ points }}</h1></div> -->
        <span style="float: right;"><img src="login.jpg" style="width: 100px; border-radius: 10px;"></span>
        <h1 style="font-size: 1.6rem"><b>KPI BONUS</b></h1>
        <br />
        <h1 style="font-size: 1.0rem">¡Hola {{user_name.split(' ')[0]}}!</h1>
        <br />
      </CCol>
    </CRow>
    <CRow>
      <CCol md="12">
        <br />
      </CCol>
    </CRow>
    <CRow>
      <CCol md="12" style="text-align: center">
        <CButton color="info" size="sm" style="margin: 5px; font-size: 14px;">Concursos y Retos</CButton>
        <CButton color="default" size="sm" style="margin: 5px; font-size: 14px;">Recompensas</CButton>
        <CButton color="default" size="sm" style="margin: 5px; font-size: 14px;">Mi cuenta</CButton>
      </CCol>
    </CRow>
    <CRow>
      <CCol md="12">
        <br />
      </CCol>
    </CRow>
    <CRow>
      <CCol md="3" v-for="(challenge, index) in challenges" :key="index">
        <CCard>
          <CCardHeader>
            {{challenge.name}}
            <div class="card-header-actions">
              <a v-if="challenge.id > 1" :href="'/#/challenge/'+challenge.id+'/view'" class="card-header-action" style="font-weight: bold;">{{challenge.enabled && challenge.left > 0 ? 'Participar' : 'Completado'}}</a>
              <a v-if="challenge.id == 1" :href="'/#/challenge/'+challenge.id+'/initial'" class="card-header-action" style="font-weight: bold;">{{challenge.enabled ? 'Participar' : 'Completado'}}</a>
            </div>
          </CCardHeader>
          <CCardBody>
            <img v-if="!challenge.image_filename" src="no-image.jpg" style="width: 100%">
            <img v-if="challenge.image_filename" :src="challenge.image_filename" style="width: 100%;">
          </CCardBody>
          <CCardFooter>
            <p style="text-align: justify;  font-size: 14px;">{{challenge.description}}</p>
            <div style="float: right; font-size: 11px;" v-if="challenge.enabled && challenge.quantity > 0">Premios Disponibles: {{challenge.left}}</div>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>

import store from '../../../store'
import challenges_ws from '../../../services/challenges';
import account_ws from '../../../services/account';
import customer_ws from '../../../services/customers';

import TopCustomersTable from '../../../components/dashboard/TopCustomersTable.vue'
import DetalleCustomersTable from '../../../components/dashboard/DetalleCustomersTable.vue'
import ProfitCustomersTable from '../../../components/dashboard/ProfitCustomersTable.vue'

export default {
  name: 'SuppliersHome',
  components: {
    TopCustomersTable,
    DetalleCustomersTable,
    ProfitCustomersTable
  },
  data () {
    return {
      name: '',
      user_name: '',
      route_name: '',
      top_customers: [],
      detalle_customers: [],
      profits_customers: [],
      total: 0,
      points: 0,
      challenges: false
    }
  },
  mounted: async function() {
    let response = await account_ws.getInfo(); 

    if(response.type == "success"){
      this.name = response.data.name;
      this.route_name = response.data.user.first_name;
      this.user_name = response.data.user.last_name;
    }

    let response2 = await customer_ws.getBySupplier(response.data.user.id); 

    if(response2.type == "success"){
      this.top_customers = response2.data.top;
      this.detalle_customers = response2.data.detalle;
      this.profits_customers = response2.data.profits;
      this.total = response2.data.total;
    }

    let response3 = await account_ws.getPoints(); 

    if(response3.type == "success"){
      this.points = response3.data;
    }

    let response4 = await challenges_ws.getActives(); 

    if(response4.type == "success"){
      this.challenges = response4.data;
    }
  },
  methods: {
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    },
    async refreshTopCustomers (top_customers){
      this.loading();
      this.top_customers = await top_customers;
      this.loaded();
    },
  }
}
</script>
